import * as React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import nightOwlTheme from "prism-react-renderer/themes/nightOwl";
import nightOwlLightTheme from "prism-react-renderer/themes/nightOwlLight";
import rangeParser from "parse-numeric-range";
import { CodeFenceWrapper } from "./CodeFence.style";
import { ThemeContext } from "styled-components";

const calculateLinesToHightlight = (raw) => {
  const lineNumbers = rangeParser(raw);
  if (lineNumbers) {
    return (index) => lineNumbers.includes(index + 1);
  } else {
    return () => false;
  }
};

const copyToClipboard = (str) => {
  if (navigator.clipboard) {
    // Most modern browsers support the Navigator API
    navigator.clipboard.writeText(str).then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  } else if (window.clipboardData) {
    // Internet Explorer
    window.clipboardData.setData("Text", str);
  }
};

const CodeFence = (props) => {
  // console.log(props);
  const currentTheme = React.useContext(ThemeContext);
  // console.log("Current theme: ", currentTheme);
  const className = props.children.props.className || "";
  const code = props.children.props.children.trim();
  const metadata = className.split(":");
  const language = metadata[0].replace(/language-/, "");
  const title = metadata[1] ? metadata[1].replace("title=", "") : "";
  const linesToHighlights = calculateLinesToHightlight(
    metadata[2] ? metadata[2].replace("highlights=", "") : ""
  );
  // for the copy buton
  const [isCopied, setIsCopied] = React.useState(false);
  return (
    <CodeFenceWrapper>
      <div className="code__whole">
        <div className="code__top">
          <div className="code__language">{`${language}`}</div>
          <div className="code__title">{title && `${title}`}</div>
          <div style={{ flexGrow: "1" }}></div>
          <button
            className="code__copy_button"
            onClick={() => {
              copyToClipboard(code);
              setIsCopied(true);
              setTimeout(() => setIsCopied(false), 3000);
            }}
          >
            {isCopied ? "🎉 Copied!" : "Copy"}
          </button>
        </div>
        <div className="code__block">
          <Highlight
            {...defaultProps}
            code={code}
            language={language}
            theme={currentTheme.dark ? nightOwlTheme : nightOwlLightTheme}
          >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre
                className={className}
                style={{
                  ...style,
                  backgroundColor: "transparent",
                  float: "left",
                  minWidth: "100%",
                }}
              >
                {tokens.map((line, i) => (
                  <div
                    {...getLineProps({ line, key: i })}
                    style={{
                      background: linesToHighlights(i)
                        ? currentTheme.codeHightlight
                        : "transparent",
                      display: "block",
                    }}
                  >
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                ))}
              </pre>
            )}
          </Highlight>
        </div>
      </div>
    </CodeFenceWrapper>
  );
};

export default CodeFence;
