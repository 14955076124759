import * as React from "react";
import { MDXProvider } from "@mdx-js/react";
import CodeFence from "./Blog/CodeFence";

const components = {
  pre: (props) => <CodeFence {...props} />,
  wrapper: ({ children }) => <>{children}</>,
};

export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
