import { wrapRootElement as wrap } from "./src/components/wrap-root-element";

require("typeface-montserrat");
require("typeface-karla");
// require("katex/dist/katex.min.css");

// require("./src/styles/prism-purpleking-dark.css");
// require("prismjs/plugins/line-numbers/prism-line-numbers.css");

export const wrapRootElement = wrap;
