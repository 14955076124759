import styled from "styled-components";

export const CodeFenceWrapper = styled.section`
  .code__whole {
    background: ${(props) => props.theme.codeBackground};
    border-radius: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 1.5rem;
    font-size: 16px;
  }

  .code__top {
    display: flex;
    position: relative;
  }

  .code__language {
    background: ${(props) => props.theme.codeLangBackground};
    margin-right: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-transform: uppercase;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.secondaryColor};
  }

  .code__title {
    color: ${(props) => props.theme.codeTitleColor};
    font-family: Montserrat;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .code__block {
    overflow: auto;
    background: ${(props) => props.theme.codeBackground};
    border-radius: 0.5rem;
  }

  .code__copy_button {
    margin-right: 1.5rem;
    margin-top: 0.5rem;
    padding: 8px 12px;
    background: ${(props) => props.theme.codeHightlight};
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: #e2e8f0;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    color: ${(props) => props.theme.primaryText};
  }

  // .code_pre {
  //   backgroundcolor: transparent;
  //   float: left;
  //   minwidth: 100%;
  // }
`;
